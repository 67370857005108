body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  vertical-align: center;
  padding: 2rem;
  padding-right: 2rem;
  height: 10vh;
  background-color: rgb(25, 73, 99);
  color: rgb(236, 241, 243);
}

.header-nav-links {
  display: flex;
  gap: 2rem;
  font-size: 1.1rem;
  margin-top: 3rem;
  letter-spacing: 1px;
  margin-right: 3rem;
}

@media (max-width: 1080px) {
  .header-wrapper {
    padding: 14px;
  }
}

span {
  color: rgb(28, 198, 245);
}

h1 {
  font-weight: 900;
  font-size: 1.2rem;
}

h6 {
  margin-top: 0;
}

.top-banner-container {
  height: 90vh;
  background-color: rgb(47, 143, 194);
  color: #fffbfb;
  background-image: url(https://i.imgur.com/tT4Enl6.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  vertical-align: center;
  text-align: left;
  padding-left: 20%;
  padding-right: 20%;
  line-height: 4rem;
}

.pressure-washing-title {
  font-size: 2rem;
  font-weight: 900;
}
.pressure-washing-quote {
  margin-bottom: 1rem;
}

.comparison-container {
  background-color: rgb(6, 58, 86);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
}

.contact-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: rgb(25, 73, 99);
  background-color: #fffbfb;
  border: 2px solid #337dae;
  border-radius: 4rem;
  margin-right: 2rem;
  width: 200px;
}

.phone-icon {
  transform: rotate(90deg);
}

.services-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  background-color: rgb(232, 239, 241);
  padding: 25px;
}
.pure-clean-services {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgb(232, 239, 241);
  padding: 100px 40px 40px 40px;
}

.power-washing-service {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: larger;
  font-weight: 800;
  border: 1px solid #34afed;
  margin: 5px;
  border-radius: 40px;
  height: 20rem;
  width: 15rem;
  flex-basis: calc(33.33% - 10px); /* Adjust as needed */
  margin: 5px; /* Adjust as needed */
  background-image: url(https://i.imgur.com/LrFLjqE.jpg);
  background-size: cover;
}

.sealing-service {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: larger;
  font-weight: 800;
  flex-basis: calc(33.33% - 10px); 
  margin: 5px; 
  border: 1px solid #34afed;
  border-radius: 40px;
  height: 20rem;
  width: 15rem;
  background-image: url(https://i.imgur.com/zFmx0vr.jpg);
  background-size: cover;
}

.softwashing-service {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: larger;
  font-weight: 800;
  flex-basis: calc(33.33% - 10px); 
  margin: 5px; 
  border: 1px solid #34afed;
  border-radius: 40px;
  height: 20rem;
  width: 15rem;
  background-image: url(https://i.imgur.com/tVoZZZ7.jpg);
  background-size: cover;
}

.slider-title {
  display: flex;
  justify-content: center;
  vertical-align: center;
  font-size: 1.5rem;
  padding: 100px 40px 10px 40px;
  height: 10vh;
  background-color: rgb(6, 58, 86);
  color: #fffbfb;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 25%;
  background-color: #fffbfb;
  border: 4px solid #fffbfb;
  border-radius: 4px;
  height: 325px;
  width: 25%;
  overflow: hidden;
  padding: 1px 1px 0px 1px;
  margin-bottom: 40px;
}

@media (max-width: 1280px) {
  .slider {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 70%;
    margin-left: 15%;
    height: 225px;
  }
}

.reviews-container {
  height: 100vh;
  background-color: rgb(36, 136, 136);
}

.contact-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-left: 7%;
  padding-right: 10%;
  height: 10vh;
  background-color: #337dae;
  color: white;
  font-size: .9rem;
  font-weight: 600;
}

.review-header {
  color:black;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  background-color: rgb(232, 239, 241);
  padding: 4rem;
}

.quote-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  vertical-align: center;
  height: 80vh;
  background-color: rgb(232, 239, 241);
  width: 100%;
}

.quote-form {
  display: flex;
  flex-direction: column;
  line-height: 2rem;
  border: 5px;
}

label {
  font-weight: 500;
  color: black
}

input {
  border: solid 1px;
  border-radius: 8px;
  border-color: #34afed;
  height: 1rem;
  margin-bottom: 2rem;
  padding: .5rem
}

textarea {
  border: solid 1px;
  border-radius: 8px;
  border-color: #34afed;
  height: 15rem;
  width: 15rem;
  margin-bottom: 2rem;
  padding: .5rem;
}

.quote-header {
  color:black;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  background-color: rgb(232, 239, 241);
  padding: 3.5rem;
}

.angis-reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(232, 239, 241)
}

.angi-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.send-button {
  color: #fffbfb;
  background-color:#337dae;
  padding: 0;
  height: 30px;
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  height: 10vh;
  background-color: rgb(6, 58, 86);
  color: #fffbfb;
}

.logo {
  height: 6rem;
}

.footer-links {
  display: flex;
  gap: 2rem;
  font-size: 1.1rem;
  letter-spacing: 1px;
}
